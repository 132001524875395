import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout composition='EmptyBars.org - Contribute'>
    <SEO title="Contribute"/>
    <ul>
        THE PAGE IS IN DEVELOPMENT.
    </ul>
    </Layout>
)

export default NotFoundPage
